import "../page-templates/ContentPage.scss";

import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import FullTemplate from 'src/templates/FullTemplate';
import GtmDataLayer from 'src/components/GtmDataLayer';

const getCorrectPathKey = (paths, sitemap) => {
  let result = paths.filter(path => window.location.pathname.includes(path))[0];
  let sitedata = sitemap.filter(map => map.key === result)[0];
  let redirectVersionData = (sitedata.tagged) ? 
    sitedata.tagsDropdown.filter(tag => window.location.pathname.includes(tag.version))[0] : "";
  let redirectVersion = (redirectVersionData) ? redirectVersionData.version : "latest";
  let returnStmt = (!sitedata.tagged) ? result : (result !== '/') ? result+"/"+redirectVersion : "/"+redirectVersion;

  return returnStmt;
}

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        pathPrefix
        siteMetadata {
          isCollection
          sitemap {
            key,              
            org,
            repo,
            root,
            tagged,
            tagsDropdown {
              version
            }
          }
          listOfRootPaths
        }
      }
      allMarkdownRemark {
        nodes {
          fields {
            articleTitle
            contentTitle
            currentDDVersion
            currentVersion
            hasPrimaryNavigation
            headTagTitle
            importedSummary
            internalSummary
            isCollection
            isRoot
            isTagged
            latestDDVersion
            organization
            originalKey
            pathData
            pathPrefixInternal
            primaryNavData
            repository
            showSearch
            slug
            summaryJson
            tagsList
          }
        }
      }
    }
  `);
  const { 
    site: { pathPrefix, siteMetadata: { listOfRootPaths, sitemap, isCollection: siteIsCollection } },
    allMarkdownRemark: { nodes }
  } = data;
  let siteIsTagged = sitemap.filter(map => map.key === listOfRootPaths[0])[0];
  let nonRootPath = listOfRootPaths.filter(path => path !== "/")[0];
  let backupPath = (nonRootPath) ? nonRootPath : "/";
  let backupSite = sitemap.filter(map => map.key === backupPath)[0];
  let redirectVersion = "latest";
  let rootPath = "/";
  let redirect = "/";
  let redirectVersionData = (siteIsTagged.tagged && typeof window !== 'undefined') ? 
    siteIsTagged.tagsDropdown.filter(tag => window.location.pathname.includes(tag.version))[0] : "latest";

  if (redirectVersionData) 
    redirectVersion = redirectVersionData.version;

  rootPath = (siteIsTagged.tagged) ? "/"+redirectVersion+"/index" : "/"
  redirect = (typeof window === 'undefined') ? rootPath : getCorrectPathKey(listOfRootPaths.reverse(), sitemap); 

  let defaultNode = (siteIsTagged.tagged) ? 
    nodes.filter(node => node.fields.slug === "/latest/index")[0]
  : nodes.filter(node => node.fields.slug === "/")[0];
  let searchDataNode = nodes.filter(node => node.fields.slug === redirect+"/index")[0];
  let rootDataNode = (searchDataNode) ? searchDataNode : nodes.filter(node => node.fields.slug === rootPath)[0];

  if (siteIsCollection && !rootDataNode) {
    rootDataNode = (backupSite.tagged) ?
      nodes.filter(node => node.fields.slug === backupPath+"/latest/index")[0]
    : nodes.filter(node => node.fields.slug === backupPath+"/index")[0];
    redirect = (backupSite.tagged) ? backupPath+"/latest/index" : backupPath;
  }

  if (!rootDataNode && !siteIsCollection) {
    rootDataNode = defaultNode;
  }
  
  const {
    fields: {
      contentTitle,
      headTagTitle,
      isCollection,
      isTagged,
      organization: orgName,
      originalKey: pathKey,
      pathData,
      repository: repoName,
      tagsList,
      latestDDVersion,
      importedSummary,
      hasPrimaryNavigation,
      primaryNavData,
      currentVersion,
      currentDDVersion,
      summaryJson,
    }
  } = rootDataNode;

  const hidesVersion = null;
  const pathInfo = JSON.parse(pathData);
  const summary = JSON.parse(summaryJson);
  const mainNavData = (primaryNavData) ? JSON.parse(primaryNavData) : [{"text":"NONE","path":"EMPTY"}];

  return (
    <FullTemplate
      orgName={orgName}
      repoName={repoName}
      contentTitle={contentTitle}
      currentVersion={currentVersion}
      hidesVersion={hidesVersion}
      showSearch={false}
      isTagged={isTagged}
      title={headTagTitle}
      summary={summary}
      pathData={pathInfo}
      slugs={nodes}
      currentDDVersion={currentDDVersion}
      latestDDVersion={latestDDVersion}
      isCollection={isCollection}
      hasPrimaryNavigation={hasPrimaryNavigation}
      primaryNavData={mainNavData}
      tagsList={tagsList}
      importedSummary={importedSummary}
      pathKey={pathKey}
      pathPrefix={pathPrefix}
      minimap={sitemap}
      showMenuLinks={true}
    >
      <GtmDataLayer
        orgName={orgName}
        repoName={repoName}
        currentVersion={currentVersion}
        hidesVersion={hidesVersion}
        />
      <article className="ContentPage document-page-container">
        <section>
          <div className="sidestep-404">
            <h1>404: Page not Found</h1>
            <p>
              We were unable to find the page you were looking for. Here are some things you can try:
            </p>
            <ul>
              <li>Return to the <Link to={redirect}>homepage</Link></li>
              <li>Use the search bar</li>
            </ul>
          </div>
        </section>
      </article>
    </FullTemplate>
  );
}
