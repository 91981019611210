import React from "react";
import { Helmet } from 'react-helmet';

const GtmDataLayer = ({orgName, repoName, currentVersion, hidesVersion}) =>
  <Helmet script={[{
            type: `text/javascript`,
            innerHTML: `window.dataLayer = [{
              organization: "${orgName}",
              repository: "${repoName}",
              version: "${currentVersion}",
              hidesVersion: "${hidesVersion}"
            }]`
  }]} />

export default GtmDataLayer;
